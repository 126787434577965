// import CSS
import styles from '../css/app.scss';

// import fonts
// import fontname  from '../fonts/fontname.woff';
// import fontname2 from '../fonts/fontname.woff2';

import anime from 'animejs/lib/anime.es.js';

// app main
const main = async () => {

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue')

    // load the vue waypoint
    const { default: VueWaypoint } = await import(/* webpackChunkName: "vuewaypoint" */ 'vue-waypoint')
    Vue.use(VueWaypoint)

    // load the vue confetti
    const { default: VueConfetti } = await import(/* webpackChunkName: "vueconfetti" */ 'vue-confetti')
    Vue.use(VueConfetti)

    // load vue cookies
    const { default: VueCookies } = await import(/* webpackChunkName: "vuecookies" */ 'vue-cookies')
    Vue.use(VueCookies)  

    // Create our vue instance
    const vm = new Vue({
        el: "#page-container",

        components: {
            'logo': ()              => import( /* webpackChunkName: "logo" */ '../vue/Logo.vue'),
            'emergencymessage': ()  => import( /* webpackChunkName: "emergencymessage" */ '../vue/EmergencyMessage.vue'),
            'mobilemenu': ()        => import( /* webpackChunkName: "mobilemenu" */ '../vue/MobileMenu.vue'),
            'blocktextimage': ()    => import( /* webpackChunkName: "blocktextimage" */ '../vue/BlockTextImage.vue'),
            'moremenu': ()          => import( /* webpackChunkName: "moremenu" */ '../vue/MoreMenu.vue'),
            'confetti': ()          => import( /* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
            'clientlogos': ()       => import( /* webpackChunkName: "clientlogos" */ '../vue/ClientLogos.vue'),

            'teamillustration': ()  => import( /* webpackChunkName: "teamillustration" */ '../vue/illustrations/Team.vue'),
            'webdevbasicillustration': ()  => import( /* webpackChunkName: "webdevbasicillustration" */ '../vue/illustrations/WebDevBasic.vue'),
        },

        props: {
            block: {
                type: Object
            },
        },

        data: {
            intersectionOptions: {
                threshold: [0.35,0.5]
            }
        },

        methods: {
            recaptchaSubmit(event) {

                let formtype = event.target.dataset.type || 'Contact Form'
                let sitekey  = event.target.dataset.sitekey
    
                if( sitekey ) {
                    grecaptcha.ready(function() {
                        try {
                            grecaptcha.execute( sitekey, { action: formtype } ).then(function(token) {
                                let input = document.createElement('input');
                                input.setAttribute("type", "hidden");
                                input.setAttribute("name", "g-recaptcha-response");
                                input.setAttribute("value", token);
        
                                event.target.prepend(input);
                                event.target.submit();
                            });
                        } catch(err) {
                            alert("Problem verifying your submission. Please refresh and try again.")
                        }
                    });
                } else {
                    alert("Problem verifying your submission. Please refresh and try again.")
                }
            },

            handleScroll (event) {
                if( window.pageYOffset >= 10 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset >= 200 ) {
                    document.body.classList.add("scrolled-200");
                }

                if( window.pageYOffset < 200 ) {
                    document.body.classList.remove("scrolled-200");
                }                

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }
            },

            onWaypoint({ el, going, direction, _entry }) {
                if( _entry.intersectionRatio > 0.3 && this.$waypointMap.GOING_IN === going ) {
                    el.classList.add('active')
                }

                if( _entry.intersectionRatio > 0.5 && this.$waypointMap.GOING_IN === going ) {
                    el.classList.add('halfin')

                    // table on contents active item highlighting
                    if( el.dataset.toc && document.querySelector('#' + el.dataset.toc) ) {

                        let tocItem = document.querySelector('#' + el.dataset.toc)
                        let level = tocItem.classList.contains('level1') ? 'level1' : 'level2'

                        let tocLinks = document.querySelectorAll('nav.c-toc a.'+level);
                        tocLinks.forEach(function(item){
                            item.classList.remove('active')
                        });

                        tocItem.classList.add( 'active' );

                        if( level == 'level2'
                            && tocItem.parentElement.previousSibling
                            && !tocItem.parentElement.previousSibling.classList.contains('active') ) {

                            let tocLinks = document.querySelectorAll('nav.c-toc a.level1');
                            tocLinks.forEach(function(item){
                                item.classList.remove('active')
                            });

                            tocItem.parentElement.previousSibling.classList.add('active')
                        }
                    }
                }
            },

            async swapImage(newImageID, containerID) {
                let container = document.getElementById( containerID )

                let currentDiv = document.querySelector( '#' + containerID + ' div.picture-wrapper.active')
                let nextDiv    = document.querySelector( '#' + newImageID )

                var children = event.target.parentNode.parentNode.childNodes;
                children.forEach(function(item){
                    item.querySelector('a').classList.remove('active')
                });
                event.target.classList.add('active')

                anime.timeline({loop: false})
                .add({
                    targets: '#'+containerID,
                    translateY: [{
                        value: "-2.5rem",
                        easing: "cubicBezier(.5, .05, .1, .3)"
                    }],
                    opacity: [{
                        value: 0,
                        easing: 'linear'
                    }],
                    duration: 500,
                    complete: function(anim) {
                        currentDiv.classList.remove('active')
                        nextDiv.classList.add('active')
              
                        anime.timeline({loop: false})
                        .add({
                            targets: '#'+containerID,
                            translateY: [{
                                value: "0rem",
                                duration: 0
                            }],
                            opacity: [{
                                value: 1,
                                easing: 'linear'
                            }],
                            delay: 250,
                            duration: 500
                        });
                    }
                })

                if( containerID ) {
                    let scrollLocation = document.getElementById(containerID)
                    let scrollBounding = scrollLocation.getBoundingClientRect();
    
                    if( scrollBounding.top < 100 ) {
                        window.scrollTo({
                            top: scrollBounding.top + document.documentElement.scrollTop - 100,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                }
            }
        },

        mounted() {
            let textWrapper = document.querySelector('.ml .letters');
            if( textWrapper ) {
                textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
            
                anime.timeline({loop: false})
                    .add({
                        targets: '.ml6 .letters',
                        opacity: [0, 1],
                        duration: 250
                    }) 
                    .add({
                        targets: '.ml6 .letter',
                        translateY: ["1.2em", 0],
                        translateZ: 0,
                        duration: 750,
                        delay: (el, i) => 50 * i
                    });

                anime.timeline({loop: false})
                    .add({
                        targets: '.ml11 .letters',
                        opacity: [0, 1],
                        duration: 250
                    })                 
                    .add({
                      targets: '.ml11 .letter',
                      opacity: [0,1],
                      easing: "easeOutExpo",
                      duration: 600,
                      delay: (el, i) => 34 * (i+1)
                    });
            }

            let firstSearch = document.querySelector('.page-search input[type=search]:first-of-type');
            if( firstSearch ) {
                firstSearch.focus();
            }

        },

        created () {
            document.body.classList.add("js-loaded");
            window.addEventListener('scroll', this.handleScroll);
        },
       
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    });

    return vm;
};

// Execute async function
main().then( (vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
